import {
  BranchSettings,
  MobileAppSettings,
  MobileCategoriesViewOptions, MobileItemsListViewOptions,
} from "@/config/Constants";
import {formatListOptions} from "@/util/formatters";

const createObj = (configModel) => {
  const model = {};
  Object.keys(configModel).map(x => model[x] = null);
  return model
}

export const models = createObj(MobileAppSettings);


export const options = {
  CategoriesViewOptions:formatListOptions(MobileCategoriesViewOptions),
  ItemsListViewOptions:formatListOptions(MobileItemsListViewOptions)
}

export const fields = [
  {
    label: 'Mobile App Settings',
    type: "spacer",
    line: false,
    class: ""
  },
  {
    label: '',
    type: "spacer",
    line: true,
    class: ""
  },
  {
    label: 'Choose Cover Screen',
    name: MobileAppSettings.CoverScreen,
    placeholder: 'Upload your amazing cover screen saver',
    type: 'file',
    multiple: false,
    value: '',
    class: 'col-sm-6',
    bodyClasses: 'single-image',
    validation_messages: {
      required: 'This field is required'
    }
  },
  {
    label: 'Choose Header Background',
    name: MobileAppSettings.HeaderBackground,
    placeholder: 'Upload your amazing background',
    type: 'file',
    multiple: false,
    value: '',
    class: 'col-sm-6',
    bodyClasses: 'single-image',
    validation_messages: {
      required: 'This field is required'
    }
  },
  {
    label: 'Preview Preferences',
    type: "spacer",
    line: true,
    class: ""
  },
  {
    label: 'Categories View',
    name: MobileAppSettings.CategoriesView,
    placeholder: '',
    type: "select",
    value: "",
    class: 'col-sm-4',
  },
  {
    label: 'Items List View',
    name: MobileAppSettings.ItemsListView,
    placeholder: '',
    type: "select",
    value: "",
    class: 'col-sm-4',
  },
  {
    label: '',
    type: "spacer",
    line: true,
  },
  {
    label: 'Preview Cart',
    name: MobileAppSettings.CartVisible,
    placeholder: '',
    type: 'checkbox',
    change: 'checkHasValue',
    class: 'ps-4 pt-4 col-sm-12 col-lg-4',
  },
  {
    label: 'Enable Ordering',
    name: MobileAppSettings.EnableOrdering,
    placeholder: '',
    type: 'checkbox',
    change: 'checkHasValue',
    class: 'ps-4 pt-4 col-sm-12 col-lg-4',
  },
  {
    label: 'Preview Prices',
    name: MobileAppSettings.PreviewPrices,
    placeholder: '',
    type: 'checkbox',
    change: 'checkHasValue',
    class: 'ps-4 pt-4 col-sm-12 col-lg-4',
  },

];
